import mitt from 'mitt'

export const mittAsyncFunction = (all) => {
    const inst = mitt(all)

    inst.emitAsync = async function (type, e) {
        let handlers = this.all.get(type)
        if (handlers) for (const f of handlers) await f(e)

        handlers = this.all.get('*')
        if (handlers) for (const f of handlers) await f(type, e)
    }

    return inst
}

export const mittAsync = mittAsyncFunction()

export default mitt()
